/* eslint-disable max-len */
import React from 'react';
import SharedPage from './shared';
import { Link } from '../../../../components';
import { KeyboardShortcut } from '@hudl/uniform-ui-components';

const IndexPage = ({}) => {
  const visualItems = [
    {
      text: 'Use Uniform CSS color variables whenever possible.',
    },
    {
      text: (
        <React.Fragment>
          Use the&nbsp;
          <Link href="https://storybook.js.org/blog/accessibility-testing-with-storybook/">
            Storybook Accessibility add-on
          </Link>
          &nbsp; Color Simulator tool to test interfaces with colorblind
          simulators.
        </React.Fragment>
      ),
    },
    {
      text:
        'Use another indicator in addition to color to show a change in state (icons, borders, etc.).',
    },
    {
      text: (
        <React.Fragment>
          Test the interface on multiple responsive breakpoints and orientations
          using your browser or the&nbsp;
          <Link href="https://storybook.js.org/docs/react/essentials/viewport">
            Storybook Viewport add-on
          </Link>
          .
        </React.Fragment>
      ),
    },
    {
      text: 'Verify that internationalized text does not break the interface.',
    },
    {
      text:
        'Verify that increasing the browser or device font size preference to 200% or “Very Large” does not break the interface.',
    },
    {
      text:
        'Verify that increasing the browser or device zoom to 200% does not break the interface.',
    },
    {
      text: (
        <React.Fragment>
          Use the <code>&nbsp;prefers-reduced-motion&nbsp;</code> media query to
          disable or modify non-essential animations in CSS.
        </React.Fragment>
      ),
    },
  ];

  const interactiveItems = [
    {
      text: (
        <React.Fragment>
          Add <code>&nbsp;tabIndex=”0”&nbsp;</code> to interactive elements that
          do not already have an implied <code>&nbsp;tabIndex</code>. Never use
          values other than 0 or -1.
        </React.Fragment>
      ),
    },
    {
      text:
        'Strive for mobile touch targets of at least 44px x 44px, except when placed inline.',
      helpText:
        "This is a WCAG 2.1 AAA rule, so it's not always required, but should be the goal.",
    },
    {
      text: (
        <React.Fragment>
          Test the tab order defined by the designs to ensure proper order,
          focus state and interaction using the{' '}
          <KeyboardShortcut className="uni-margin--half--horizontal">
            space
          </KeyboardShortcut>{' '}
          and/or{' '}
          <KeyboardShortcut className="uni-margin--half--horizontal">
            enter
          </KeyboardShortcut>{' '}
          keys.
        </React.Fragment>
      ),
    },
    {
      text: (
        <React.Fragment>
          Allow any dialogs or overlays to be closed using the{' '}
          <KeyboardShortcut className="uni-margin--half--horizontal">
            esc
          </KeyboardShortcut>{' '}
          key.
        </React.Fragment>
      ),
    },
    {
      text:
        'Use proper semantic tags, ARIA properties and roles, and logical ordering of markup.',
    },
    {
      text: (
        <React.Fragment>
          Add the <code>&nbsp;aria-hidden&nbsp;</code> property to any
          decorative SVGs or other elements that a screen reader can safely
          ignore.
        </React.Fragment>
      ),
    },
  ];

  const automationItems = [
    {
      text: (
        <React.Fragment>
          Use the&nbsp;
          <Link href="https://github.com/nickcolley/jest-axe">
            jest-axe plugin
          </Link>
          &nbsp; to add accessibility snapshot tests to your unit tests.
        </React.Fragment>
      ),
    },
    {
      text: (
        <React.Fragment>
          Use the&nbsp;
          <Link href="https://www.npmjs.com/package/stylelint-a11y">
            stylelint-a11y plugin
          </Link>
          &nbsp; to find accessibility issues in your stylesheets.
        </React.Fragment>
      ),
    },
    {
      text: (
        <React.Fragment>
          Use the&nbsp;
          <Link href="https://marketplace.visualstudio.com/items?itemName=deque-systems.vscode-axe-linter">
            Visual Studio Code axe Accessibility Linter extension
          </Link>
          &nbsp;to find accessibility issues in your markup.
        </React.Fragment>
      ),
    },
    {
      text: (
        <React.Fragment>
          Use the&nbsp;
          <Link href="https://storybook.js.org/blog/accessibility-testing-with-storybook/">
            Storybook Accessibility add-on
          </Link>
          &nbsp; panel to verify there aren't any accessibility violations.
        </React.Fragment>
      ),
    },
    {
      text: (
        <React.Fragment>
          Use the&nbsp;
          <Link href="https://chrome.google.com/webstore/detail/axe-devtools-web-accessib/lhdoppojpmngadmnindnejefpokejbdd">
            Chrome axe dev tools extension
          </Link>
          &nbsp; to check for violations (if you're not using Storybook).
        </React.Fragment>
      ),
    },
    {
      text: (
        <React.Fragment>
          Include interactive tests for keyboard navigation to the project's
          automated tests using&nbsp;
          <Link href="https://playwright.dev/">Playwright</Link>
          &nbsp; or another library.
        </React.Fragment>
      ),
    },
    {
      text: (
        <React.Fragment>
          Add the&nbsp;
          <Link href="/guidelines/accessibility/resources/code">
            Accessibility PR Checklist
          </Link>
          &nbsp; to the project repository's PR template file.
        </React.Fragment>
      ),
    },
  ];

  return (
    <SharedPage
      pageType="code"
      visualItems={visualItems}
      interactiveItems={interactiveItems}
      automationItems={automationItems}
    />
  );
};

export default IndexPage;
